import { StrictOmit } from '../../utils/types/strict-omit';
import { BulkUpdateInfo } from '../bulk-update-info';

import { TermSummary } from './term-summary';
import { TermData } from './term-data';

/** Term (detailed model). */
export class Term extends TermSummary {

  /** Term data. */
  public readonly data: TermData;

  /** Bulk update info. */
  public readonly bulkUpdateInfo: BulkUpdateInfo;

  public constructor(data: TermInitArgs) {
    super({
      ...data,
      bulkUpdateStatus: data.bulkUpdateInfo.status,
    });

    this.data = data.data;
    this.bulkUpdateInfo = data.bulkUpdateInfo;
  }
}

type TermInitArgs = StrictOmit<Term, 'name' | 'bulkUpdateStatus'>;
