import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { AbstractControl, NonNullableFormBuilder, ValidatorFn, ReactiveFormsModule } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { skip } from 'rxjs/operators';
import { MatFormField, MatError } from '@angular/material/form-field';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { ValidationErrorCode } from '@dartsales/common/core/models/errors/validation-error-code';
import { FilterComponentType, injectFilterControl } from '@dartsales/common/core/models/filters/multi-condition-filters/filter-component';
import { listenControlChanges } from '@dartsales/common/core/utils/rxjs/listen-control-changes';

import { ChipsListComponent } from '../../chips-list/chips-list.component';
import { ValidationMessageComponent } from '../../validation-message/validation-message.component';

/**
 * Number array ErrorStateMatcher.
 * We use this class for check if all string array members can be transform to number.
 */
class StringArrayErrorStateMatcher implements ErrorStateMatcher {

  /** @inheritdoc */
  public isErrorState(control: AbstractControl): boolean {
    return control !== null && (control.touched || control.dirty) && control.errors != null;
  }
}

/** Number array filter input component. */
@Component({
  selector: 'dartsalesc-number-array-filter-input',
  templateUrl: './number-array-filter-input.component.html',

  // We have nested form controls. 'Default' change detection is required for displaying errors.
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
  imports: [
    MatFormField,
    ChipsListComponent,
    ReactiveFormsModule,
    MatError,
    ValidationMessageComponent,
  ],
})
export class NumberArrayFilterInputComponent implements FilterComponentType<number[]>, OnInit {

  private readonly fb = inject(NonNullableFormBuilder);

  private readonly destroyRef = inject(DestroyRef);

  /** @inheritdoc */
  public readonly formControl = injectFilterControl<number[]>();

  /** Error state matcher. */
  protected readonly matcher = new StringArrayErrorStateMatcher();

  /** Chips list form control. */
  protected readonly chipsFormControl = this.fb.control<readonly string[]>([], this.numberArrayTypeValidator());

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToChipsControlChanges();
  }

  private subscribeToChipsControlChanges(): void {
    listenControlChanges(this.chipsFormControl).pipe(
      skip(1),
      takeUntilDestroyed(this.destroyRef),
    )
      .subscribe(value => {
        if (this.chipsFormControl.invalid) {
          this.formControl.setErrors(this.chipsFormControl.errors);
        }
        if (this.chipsFormControl.valid) {
          this.formControl.patchValue(value.map(item => Number(item)));
        }
      });
  }

  private numberArrayTypeValidator(): ValidatorFn {
    return (control: AbstractControl<string[]>) => {
      const stringControl = control.value.find(item => Number.isNaN(Number(item)));
      if (stringControl !== undefined) {
        return {
          [ValidationErrorCode.AppError]: {
            message: `All values must be a number`,
          },
        };
      }
      return null;
    };
  }
}
