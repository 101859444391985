import { LaborRoleHoursPricing } from './labor-role-hours-pricing';

/** Labor module data. */
export class LaborModuleData {
  /** Labor role hours pricing list. */
  public readonly laborRoleHoursPricings: readonly LaborRoleHoursPricing[];

  public constructor(data: LaborModuleDataInitArgs) {
    this.laborRoleHoursPricings = data.laborRoleHoursPricings;
  }
}

type LaborModuleDataInitArgs = Pick<
  LaborModuleData,
  | 'laborRoleHoursPricings'
>;
