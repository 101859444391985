import { Injectable, inject } from '@angular/core';

import { Term } from '@dartsales/common/core/models/estimate-services/term';
import { TermSummary } from '@dartsales/common/core/models/estimate-services/term-summary';
import { EditTerm, EditTermLockState } from '@dartsales/common/core/models/estimate-services/edit-term';

import { TermDto } from '../dto/estimate-services/term.dto';
import { IMapperFromDto } from '../mappers';
import { BasePricingOverridesMapper } from '../pricing/base-pricing-overrides.mapper';
import { EditTermOrderDto, EditTermDto, EditTermLockStateDto } from '../dto/estimate-services/edit-term.dto';
import { EstimateTermLocksStateMapper } from '../estimate/estimate-term-locks-state.mapper';
import { BulkUpdateInfoMapper } from '../bulk-update-info.mapper';

import { TermDataMapper } from './term-data.mapper';
import { TermSummaryMapper } from './term-summary.mapper';

/** Service term mapper. */
@Injectable({
  providedIn: 'root',
})
export class TermMapper
implements IMapperFromDto<TermDto, Term> {

  private readonly termDataMapper = inject(TermDataMapper);

  private readonly termSummaryMapper = inject(TermSummaryMapper);

  private readonly basePricingOverridesMapper = inject(BasePricingOverridesMapper);

  private readonly termLocksStateMapper = inject(EstimateTermLocksStateMapper);

  private readonly bulkUpdateInfoMapper = inject(BulkUpdateInfoMapper);

  /** @inheritdoc */
  public fromDto(dto: TermDto): Term {
    const { data } = dto;

    return new Term({
      ...this.termSummaryMapper.fromDto(dto),
      data: this.termDataMapper.fromDto(data),
      bulkUpdateInfo: this.bulkUpdateInfoMapper.fromDto({
        status: dto.bulkUpdateStatus,
        updatesCount: dto.termBulkUpdatesCount,
      }),
    });
  }

  /**
   * Map edit model to DTO.
   * @param model Term edit data.
   */
  public toEditDto(model: EditTerm): EditTermDto {
    return {
      number: model.number ?? undefined,
      properties: model.propertiesOverrides != null ?
        {
          overrides: this.basePricingOverridesMapper.toDto(model.propertiesOverrides),
        } :
        undefined,
    };
  }

  /**
   * Map term to lock state DTO.
   * @param data Term lock state data.
   */
  public mapToLockStateDto(data: EditTermLockState): EditTermLockStateDto {
    return {
      isTermsSellPriceLocked: data.isTermsSellPriceLocked,
      terms: data.terms.map(term => this.termLocksStateMapper.toDto(term)),
    };
  }

  /**
   * Map term to order DTO.
   * @param data Term.
   * @param order Term order.
   */
  public mapTermToOrderDto(data: TermSummary, order: number): EditTermOrderDto {
    return {
      id: data.id,
      order,
    };
  }
}
