import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { combineLatest, distinctUntilChanged, map, Observable, ReplaySubject, shareReplay, startWith } from 'rxjs';
import { MatFormField, MatError } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { AsyncPipe } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { FilterComponentType, injectFilterControl } from '@dartsales/common/core/models/filters/multi-condition-filters/filter-component';
import { ProjectLayoutService } from '@dartsales/common/core/services/project-layout.service';
import { InfiniteScrollListStrategy, ListManager } from '@dartsales/common/core/utils/list-manager';
import { PaginationData } from '@dartsales/common/core/models/list-utilities/pagination-data';
import { TagFilters } from '@dartsales/common/core/models/filters/tag-filters';
import { SortDirection } from '@dartsales/common/core/enums/sort-direction';
import { TagsApiService } from '@dartsales/common/core/services/api/tags-api.service';
import { listenControlChanges } from '@dartsales/common/core/utils/rxjs/listen-control-changes';

import { ChipsListWithSuggestedComponent } from '../../chips-list-with-suggested/chips-list-with-suggested.component';
import { FormControlValidationMessageComponent } from '../../form-control-validation-message/form-control-validation-message.component';

/** Tag filter input component. */
@Component({
  selector: 'dartsalesc-tag-filter-input',
  templateUrl: './tag-filter-input.component.html',
  styleUrls: ['./tag-filter-input.component.css'],

  // We have nested form controls. 'Default' change detection is required for displaying errors.
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
  imports: [
    MatFormField,
    ChipsListWithSuggestedComponent,
    ReactiveFormsModule,
    MatError,
    FormControlValidationMessageComponent,
    AsyncPipe,
  ],
})
export class TagFilterInputComponent implements OnInit, FilterComponentType<string[]> {
  private readonly projectLayoutService = inject(ProjectLayoutService);

  private readonly tagsApiService = inject(TagsApiService);

  private readonly destroyRef = inject(DestroyRef);

  private readonly search$ = new ReplaySubject<string>(1);

  /** Project stream. */
  protected readonly project$ = this.projectLayoutService.project$;

  /** Select options list manager. */
  protected readonly listManager = this.createOptionsListManager();

  private readonly selectedTags$ = new ReplaySubject<readonly string[]>(1);

  /** Select options list. */
  protected readonly options$ = this.createOptionsStream();

  /** @inheritdoc */
  public readonly formControl = injectFilterControl<string[]>();

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToControlChanges();
  }

  /**
   * Handle search value change.
   * @param searchValue Search value.
   */
  protected onSearchChange(searchValue: string): void {
    this.search$.next(searchValue);
  }

  private createOptionsListManager(): ListManager<string, TagFilters> {
    return new ListManager<string, TagFilters>({
      strategy: new InfiniteScrollListStrategy(),
      pagination: new PaginationData({
        pageSize: 20,
      }),
      filter$: this.createFilters(),
      initialSort: [{ field: 'name', direction: SortDirection.ASC }],
      request: options => this.tagsApiService.getTagsList(options),
    });
  }

  private createFilters(): Observable<TagFilters> {
    return combineLatest([
      this.project$,
      this.search$.pipe(distinctUntilChanged()),
    ]).pipe(
      map(([project, search]) => ({
        organizationId: project.projectInfo.details.organizationId,
        nameContains: search,
      })),
    );
  }

  private createOptionsStream(): Observable<string[]> {
    return combineLatest([
      this.listManager.items$.pipe(startWith([])),
      this.selectedTags$,
    ]).pipe(
      map(([tags, selectedTags]) => tags
        .filter(tag => !selectedTags.includes(tag))),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private subscribeToControlChanges(): void {
    listenControlChanges(this.formControl).pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => {
        this.selectedTags$.next(value);
      });
  }
}
