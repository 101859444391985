import { Injectable, inject } from '@angular/core';

import { CustomModuleData } from '@dartsales/common/core/models/estimate/modules/custom/custom-module-data';
import { CustomModule } from '@dartsales/common/core/models/estimate/modules/custom/custom-module';

import { CustomModuleDto } from '../../../dto/estimate/modules/custom/custom-module.dto';
import { IMapper } from '../../../mappers';
import { ModulePropertiesMapper } from '../module-properties.mapper';
import { BulkUpdateInfoMapper } from '../../../bulk-update-info.mapper';

import { CustomModulePricingMapper } from './custom-pricing.mapper';

/** Custom module entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class CustomModuleMapper implements
  IMapper<CustomModuleDto, CustomModule> {

  private readonly propertiesMapper = inject(ModulePropertiesMapper);

  private readonly itemsMapper = inject(CustomModulePricingMapper);

  private readonly bulkUpdateInfoMapper = inject(BulkUpdateInfoMapper);

  /** @inheritdoc */
  public fromDto(dto: CustomModuleDto): CustomModule {
    return new CustomModule({
      properties: this.propertiesMapper.fromDto(dto.properties),
      data: new CustomModuleData({
        items: dto.data.items.map(itemDto => this.itemsMapper.fromDto(itemDto)),
      }),
      bulkUpdateInfo: this.bulkUpdateInfoMapper.fromModuleDto(dto),
    });
  }

  /** @inheritdoc */
  public toDto(data: CustomModule): CustomModuleDto {
    return {
      ...this.bulkUpdateInfoMapper.toModuleDto(data.bulkUpdateInfo),
      properties: this.propertiesMapper.toDto(data.properties),
      data: {
        items: data.data.items.map(item => this.itemsMapper.toDto(item)),
      },
    };
  }
}
