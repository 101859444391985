import { ExpensesRole } from './expenses-role';
import { ExpensesEmployee } from './expenses-employee';
import { ExpensesTravelCost } from './expenses-travel-cost';
import { ExpensesPricing } from './expenses-pricing';

/** Expenses module data. */
export class ExpensesModuleData {
  /** Roles list. */
  public readonly roles: readonly ExpensesRole[];

  /** Travel cost items list. */
  public readonly travelCosts: readonly ExpensesTravelCost[];

  /** Employees list. */
  public readonly employees: readonly ExpensesEmployee[];

  /** Pricing list. */
  public readonly pricingList: readonly ExpensesPricing[];

  public constructor(data: ExpensesModuleDataInitArgs) {
    this.roles = data.roles;
    this.travelCosts = data.travelCosts;
    this.employees = data.employees;
    this.pricingList = data.pricingList;
  }
}

type ExpensesModuleDataInitArgs = Pick<
  ExpensesModuleData,
  | 'roles'
  | 'travelCosts'
  | 'employees'
  | 'pricingList'
>;
