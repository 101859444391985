import { Injectable, inject } from '@angular/core';

import {
  EditEstimateService,
  EditEstimateServiceData,
  EstimateService,
} from '@dartsales/common/core/models/estimate-services/estimate-service';
import { EstimateServicePricingMapper } from '@dartsales/common/core/services/mappers/estimate-services/estimate-service-pricing.mapper';
import { EstimateServiceModulesMapper } from '@dartsales/common/core/services/mappers/estimate-services/estimate-service-modules.mapper';

import { DateMapper } from '../date-mapper';
import { IMapperFromDto, IMapperToDto } from '../mappers';
import { EstimateServiceDto, EditEstimateServiceDto } from '../dto/estimate-services/estimate-service.dto';
import { ModulesOverridesMapper } from '../estimate/modules-overrides.mapper';
import { LocksStateModulesMapper } from '../estimate/locks-state-modules.mapper';

/** Estimate service mapper. */
@Injectable({ providedIn: 'root' })
export class EstimateServiceMapper implements
  IMapperFromDto<EstimateServiceDto, EstimateService>,
  IMapperToDto<EditEstimateServiceDto, EditEstimateService> {

  private readonly dateMapper = inject(DateMapper);

  private readonly estimateServicePricingMapper = inject(EstimateServicePricingMapper);

  private readonly estimateServiceModulesMapper = inject(EstimateServiceModulesMapper);

  private readonly moduleOverridesMapper = inject(ModulesOverridesMapper);

  private readonly locksStateModulesMapper = inject(LocksStateModulesMapper);

  /** @inheritdoc */
  public fromDto(dto: EstimateServiceDto): EstimateService {
    return {
      id: dto.id,
      name: dto.name ?? '',
      templateServiceId: dto.templateServiceId,
      description: dto.description ?? '',
      termId: dto.termId,
      categoryId: dto.subCategory?.categoryId ?? null,
      subcategoryId: dto.subCategory?.id ?? null,
      subcategoryName: dto.subCategory?.name ?? null,
      pricing: this.estimateServicePricingMapper.fromDto(dto),
      createdAt: this.dateMapper.fromDto(dto.createdAt),
      updatedAt: this.dateMapper.fromDto(dto.updatedAt),
      modulesProperties: this.estimateServiceModulesMapper.fromDto(dto),
      bulkUpdateStatus: dto.bulkUpdateStatus,
    };
  }

  /**
   * Map edit service data model to DTO.
   * @param model Model.
   */
  public toEditDataDto(model: EditEstimateServiceData): EditEstimateServiceDto {
    return {
      id: model.id,
      name: model.name,
      description: model.description,
      subCategoryId: model.subcategoryId,
      quantity: model.pricing.quantity,
      modulesSellPriceLocks: model.locksStateModules ? this.locksStateModulesMapper.toEditDto(model.locksStateModules) : undefined,
    };
  }

  /** @inheritdoc */
  public toDto(model: EditEstimateService): EditEstimateServiceDto {
    return {
      ...this.toEditDataDto(model),
      modulesOverrides: this.moduleOverridesMapper.toDto(model.modules),
    };
  }
}
