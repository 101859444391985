import { Module } from '../../module';

import { LaborModuleData } from './labor-module-data';
import { LaborModuleProperties } from './labor-module-properties';

/** Labor module data. */
export class LaborModule extends Module<LaborModuleProperties, LaborModuleData> {

  public constructor(data: LaborModuleInitArgs) {
    super(data);
  }
}

type LaborModuleInitArgs = LaborModule;
